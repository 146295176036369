@charset "UTF-8";
/*!
* Start Bootstrap - Freelancer v6.0.6 (https://startbootstrap.com/theme/freelancer)
* Copyright 2013-2021 Start Bootstrap
* Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-freelancer/blob/master/LICENSE)
*/
/*!
 * Bootstrap v4.6.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #1abc9c;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #1abc9c;
  --secondary: #2c3e50;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: 'Lato', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    'Liberation Mono', 'Courier New', monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: Inter;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0px;
}

html {
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
}

body {
  margin: 0;
  font-family: 'Inter';
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
  font-family: 'Poppins', sans-serif;
}

[tabindex='-1']:focus:not(:focus-visible) {
  outline: 0 !important;
}

input:checked + #v1-checkbox {
  @apply border-blue-100;
  @apply bg-blue-100;
}

input:checked + #v2-checkbox {
  @apply text-[#17AF30];
}

@media (min-width: 800px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

/* @media (min-width: 1600px) {
  html {
    font-size: 18px;
  }
} */

.thumbs-wrapper {
  display: flex;
  justify-content: center;
}

#custom-datePicker .react-date-picker__wrapper {
  padding: 19px 10px;
  border: transparent;
}

#message-chat .ce-chat-engine {
  background-color: transparent !important;
  height: 100%;
}

#message-chat .ce-wrapper {
  height: 100%;
}

#message-chat .ce-settings-column {
  display: none !important;
}

#message-chat .ce-chat-feed-column {
  max-width: 75% !important;
  width: 75% !important;
  padding-left: 1.75rem !important;
  padding-right: 3.75rem !important;
  flex: 1 1 auto !important;
}

#message-chat #ce-feed-container {
  display: none !important;
}

::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #52b4da;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #52b4da;
}

.card-gradient {
  background: linear-gradient(135deg, #52b4da 0%, #1e3e85 100%);
}

.footer-bg {
  background-image: url('./assets/images/footer-bg/Background_1_gnqdse_c_scale\,w_947.png');
}

.social-icons {
  background: linear-gradient(
    135deg,
    rgba(235, 237, 255, 0.15) 0%,
    rgba(173, 184, 255, 0.15) 100%
  );
}

.skill-tag {
  background: linear-gradient(
    132.56deg,
    rgba(87, 153, 235, 0.1) 5.63%,
    rgba(159, 116, 251, 0.1) 106.18%
  );
}

.carousel__list {
  display: flex;
  list-style: none;
  padding: 0;
  padding: 1rem 0 0;
  overflow: hidden;
  position: relative;
  width: 75vw;
  margin: 0 auto;
  max-width: 50rem;
}

.carousel__item {
  flex: 0 0 auto;
  width: 200px;
  padding: 0;
  margin: 0;
}

.btn-hover:hover {
  background: linear-gradient(129.98deg, #52b4da -106.35%, #1e3e85 95.25%);
  color: white;
}

.react-multi-carousel-list {
  position: static !important;
}

.disc-bg {
  background: linear-gradient(132.56deg, #52b4da 5.63%, #1e3e85 106.18%),
    #ff1f1f;
}

.avatar-bg-faq {
  background: linear-gradient(132.56deg, #ffed43 5.63%, #f29939 106.18%);
}

.premium-card-btn-bg {
  background: linear-gradient(129.98deg, #52b4da -106.35%, #1e3e85 95.25%);
}

.avatar-bg-testimonial {
  background: linear-gradient(132.56deg, #ffed43 5.63%, #f29939 106.18%);
  box-shadow: 14px 10px 30px rgba(49, 89, 211, 0.12);
  border-radius: 40px;
}

.mobile-menu {
  background: linear-gradient(131.63deg, #1e3e85 6.87%, #336da6 106.04%),
    #ffffff;
  opacity: 0.95;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@media (max-width: 768px) {
  .mobile-auth-bg {
    background: linear-gradient(131.63deg, #1e3e85 6.87%, #336da6 106.04%);
  }
  ::-webkit-scrollbar {
    width: 0px;
  }

  #message-chat .ce-chat-feed-column {
    max-width: 100% !important;
    width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    flex: 1 1 auto !important;
  }

  #message-chat .ce-chat-list-column {
    max-width: 100% !important;
    width: 100% !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    flex: 1 1 auto !important;
  }
}

@media (min-width: 992px) {
  .footer-bg {
    background-image: url('./assets/images/footer-bg/Background_1_gnqdse_c_scale\,w_947.png');
  }
  ::-webkit-scrollbar {
    width: 3px;
  }
}

@media (min-width: 1200px) {
  .footer-bg {
    background-image: url('./assets/images/footer-bg/Background_1_gnqdse_c_scale\,w_1255.png');
  }
}

@media (min-width: 1500px) {
  .footer-bg {
    background-image: url('./assets/images/footer-bg/Background_1_gnqdse_c_scale\,w_1440.png');
  }
}

.react-pdf__Page__textContent {
  display: none !important;
}

.react-pdf__Page__annotations {
  display: none !important;
}

@layer utilities {
  .shadow-box-shadow-custom {
    box-shadow: 14px 41px 50px 0px rgba(49, 89, 211, 0.07);
  }
}

/* for tinymce content */
/* Heading Elements */
.content h1 {
  display: block;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.content h2 {
  display: block;
  font-size: 1.5em;
  margin-top: 0.83em;
  margin-bottom: 0.83em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.content h3 {
  display: block;
  font-size: 1.17em;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.content h4 {
  display: block;
  font-size: 1em;
  margin-top: 1.33em;
  margin-bottom: 1.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.content h5 {
  display: block;
  font-size: 0.83em;
  margin-top: 1.67em;
  margin-bottom: 1.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

.content h6 {
  display: block;
  font-size: 0.67em;
  margin-top: 2.33em;
  margin-bottom: 2.33em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
}

/* Paragraph Element */
.content p {
  display: block;
  margin-top: 1em;
  margin-bottom: 1em;
  margin-left: 0;
  margin-right: 0;
}

.content ul {
  list-style: disc;
}
.content ol {
  list-style: auto;
}
/* this is just for content of blog */
.content.blog {
  line-height: 35px;
}

/* Carousel */
.carousel {
  position: relative;
  width: 100%;
}

.carousel-track {
  display: flex;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

.scroll-left {
  animation: scroll-left 20s linear infinite;
}

.scroll-right {
  animation: scroll-right 20s linear infinite;
}

.editor-class {
  /* background-color: #F8F8F8; */
  padding: 1rem;
  min-height: 200px;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Optional: Hide scrollbar for Firefox */
.no-scrollbar {
  scrollbar-width: none; /* Firefox */
}
