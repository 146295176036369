.registration {
  background-color: #e3e6ec;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__logo {
    position: absolute;
    top: 0;
    left: 0;
    height: 156px;
    width: 1x56px;
  }

  &__title {
    color: #11142d;
    font-size: 40px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 60px;
    margin-bottom: 24px;
  }

  &__new {
    &-text {
      color: #b2b3bd;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
    }
    &-link {
      color: #0049c6;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 16px;
      margin-left: 16px;
    }
  }
}
