/* Switch.css */
.switch {
  cursor: pointer;
  width: 40px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #1E3E85;
  transition: background-color 0.3s;
  position: relative;
}

.switch-handle {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #52B4DA;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  transform: translateX(0);
  position: absolute;
  top: -6px;
  left: 0;
}
